<template>
  <div :style="category.icon ? 'background:white': ''">
    <div style='position: fixed; left: 0; width: 33%;  background-color: #fff; z-index: -2;'></div>
    <div style='position: fixed; top: 0; right: 0; width: 33%; height: 100%; background-color: #f4f5f7; z-index: -1;'></div>
    <div class="pageContainer" style="display: flex; background-color: #f4f5f7!important;">

      <div style="display:flex;flex-direction:row; margin: 0 auto; width: 1170px;">
        <div style="display:flex;flex-direction:column; align-items:flex-end;  background-color:#ffffff;width: 230px; min-width: 230px; padding-bottom: 50px; ">
          <div style="display:flex;flex-direction:column;  margin-top:42px; ">
            <template v-if="pageLoaded || category.parent_title">
              <p v-if="category.parent_id" style="margin-bottom:15px;font-size: 14px;font-weight: 600;color: #2d3640; text-transform: uppercase">{{category.parent_title}}</p>
              <p v-else style="margin-bottom:15px;font-size: 14px;font-weight: 600;color: #2d3640;">DESTEK KATEGORİLERİ</p>
            </template>
            <div
                v-if="(category.id === 41 || item.id !== 41) && (category.id === 42 || item.id !== 42)"
                v-for="(item,index) in category.all_sub_categories"  :key="index"
                 style="padding-top:10px;padding-bottom:10px;"
                 :class="item.id === category.id ? ' ' : ''">
                <router-link
                  :class="item.id === category.id ? 'selected-category-row selected-category-item' : 'category-item'"
                  :to="'/destek/kategori/'+item.slug"
                >{{item.title}}</router-link>
              <template v-if="item.id === category.id">
                <p v-for="(item,index) in other_support_articles"  :key="index"
                   :class="item.id === article.id ? 'sub-link-p-active': 'sub-link-p'">
                  <router-link
                    :to="'/destek/'+item.slug"
                  >{{item.title}}</router-link>
                </p>
              </template>
            </div>

          </div>
        </div>


        <div v-if="!pageLoaded" style="width: 843px; margin-left:60px; margin-top: 40px; background-color: #f4f5f7;padding-bottom:70px; min-height:400px;">
          <div v-if="category.parent_id">
            <HelpNavBar :contentData="navBarData" :showButton="true" />
          </div>
          <div class="loader" style="text-align: center;"></div>
        </div>
        <div v-else style="width: 843px; margin-left:60px; margin-top: 40px;  background-color: #f4f5f7;padding-bottom:70px; min-height:400px;">

          <div style="">
            <HelpNavBar :contentData="navBarData" :showButton="true" />
          </div>

          <div class="other-category">
            <p class="main-title">{{article.title}}</p>
            <p class="main-sub-title">{{article.sub_title}}</p>
            <template v-if="article.asset">
              <video v-if="article.asset.type === 'video'"  class="mt-2" @play="sendPlayEvent(article.asset.id)" width="100%" :poster="article.asset.info.thumbnailUrl" controls>
                <source :src="article.asset.info.videoUrl" type="video/mp4">
                Tarayacınız video oynatımını desteklemiyor.
              </video>
              <img v-else :src="article.asset.info.imageUrl" width="100%" />
            </template>
            <div class="main-text" v-html="article.text" />
          </div>

          <Faqs v-if="faqData.accordionData && faqData.accordionData.length > 0" :contentData="faqData" :calledFrom="'article'"
                :cat-id="category.parent_id ? null : category.id"
                :sub-cat-id="category.parent_id ? category.id : null"
                :impression-u-u-i-d="impressionUUID"
                :article-id="this.article.id"
          />

          <div v-if="showYeterlimi" class="yeterlimi">
            <div class="support-icon-container">
              <img
                src="https://gcdn.bionluk.com/site/cicons/ic-thumb.svg"
                onload="SVGInject(this)"
                class="support-icon"
              />
            </div>
            <p class="y-text">Bu içerik faydalı oldu mu?</p>
            <button @click="rateArticle('positive')" class="y-button">Evet, yeterli.</button>
            <button @click="rateArticle('negative')" class="y-button">Hayır, hala yardıma ihtiyacım var.</button>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
  import HelpNavBar from "../../../../components/HelpNavBar";
  import Faqs from "../../../../components/Faqs";

  export default {
    name: "src-pages-body-staticContents-support-categoryList-v1",
    components: { HelpNavBar, Faqs },
    data() {
      return {
        impressionUUID: null,
        showYeterlimi: true,
        isVideoEventSend: false,
        comingPath: null,
        slug: null,
        pageLoaded: false,
        category: {
          id: null,
          parent_id: null,
          sub_categories: [],
          all_sub_categories: [],
        },
        article: {video:{id:null}},
        other_support_articles: [],
        faqData: {
          title: " hakkında merak edilen sorular?",
          text: "En çok sorduğun konuları bir araya topladık. 😇",
          accordionData: []
        },
      }
    },
    methods: {
      sendPlayEvent(id) {
        if (!this.isVideoEventSend) {
          this.Helper.trackEvents.customEvent("custom_video_view", {
            title:this.article.title,
            id,
            impression_id: this.impressionUUID,
            page: this.$store.state.componentMap.page
          });
          this.isVideoEventSend = true;
        }
      },
      rateArticle(score) {
        this.Helper.trackEvents.customEvent("rate_support_article", {title:this.article.title,type:score, article_id:this.article.id, impression_id:this.impressionUUID});
        if (score==='negative') {
          this.$router.push('/tickets?tab=new')
        } else {
          this.$toasted.global.infoToast({
            description: 'Teşekkürler 🥰'
          })
          this.showYeterlimi = false
        }
      },
      getTopics(){
        this.impressionUUID = this.Helper.generateUUID()
        this.pageLoaded = false
        this.api.content
          .retrieveSupportTopicDetailsv2(this.slug, this.comingPath)
          .then(({ data }) => {
            let result = data
            if (result.success) {

              this.$store.state.indexHTMLTitle = result.data.meta.title;
              this.$store.state.indexHTMLMetaDescription = result.data.meta.description;


              this.category = result.data.category;
              this.other_support_articles = result.data.other_support_articles;
              this.article = result.data.article;
              this.faqData.accordionData = result.data.faqs;
              this.faqData.title = this.article.title + ' hakkında merak edilen sorular?'

              this.Helper.trackEvents.pageView({title:result.data.article.title, categoryId:result.data.category.id, article_id:result.data.article.id, impression_id:this.impressionUUID});
            } else {
              this.$router.push(result.data.redirect_url);
            }
            this.pageLoaded = true;
          })
          .catch(err => {
            this.pageLoaded = true;
            this.$toasted.global.errorToast({
              description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE
            });
          });
      }
    },
    computed: {
      navBarData() {
        if (this.category.parent_title) {
          return {
            navBarList: [
              {
                title: "Destek",
                path: "/destek"
              },
              {
                title: this.category.parent_title,
                path: "/destek/kategori/"+this.category.parent_slug
              },
              {
                title: this.category.title,
                path: "/destek/kategori/"+this.category.slug
              },
              {
                title: this.article.title,
                path: "/destek/kategori/"+this.category.slug
              }
            ],
            buttonTitle: "Yardım Merkezine Dön",
            buttonPath: "/destek"
          }
        } else {
          return {
            navBarList: [
              {
                title: "Destek",
                path: "/destek"
              },
              {
                title: this.category.title,
                path: "/destek/kategori/"+this.category.slug
              },
              {
                title: this.article.title,
                path: "/destek/kategori/"+this.category.slug
              }
            ],
            buttonTitle: "Yardım Merkezine Dön",
            buttonPath: "/destek"
          }
        }

      }
    },
    watch: {
      '$store.state.route.path': function (newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          document.body.scrollTop = 0
          this.pageLoaded = false
          let pathArr = this.$store.state.route.fullPath.split('/')
          if (pathArr && pathArr[2]) {
            this.slug =  pathArr[2]
            this.getTopics();
            this.isVideoEventSend = false;
          }
        }
      }
    },
    created() {
      if (!this.comingPath) {
        this.comingPath = this.$store.state.route.from.path;
      }

      let pathArr = this.$store.state.route.fullPath.split('/')
      if (pathArr && pathArr[2]) {
        this.slug =  pathArr[2]
        this.getTopics()
      } else {
        this.$router.push('/destek')
      }
    }
  };
</script>

<style scoped lang="scss">

  .main-text{
    margin-top: 50px;
    line-height: 1.88;
  }

  .main-text >>> img{
    border-radius: 10px;
    box-shadow: 0 0 10px 0 #2d36400c!important;
    border: solid 1px #eaedf2!important;
  }


  .support-icon /deep/{
    width: 24px;
    height: 24px;
    path {
      fill: #00a575;
    }
  }

  .support-icon-container {
    margin-left: 30px;
    min-width: 60px;
    width: 60px;
    height: 60px;
    border-radius: 50px;
    box-shadow: 0 3px 6px 0 rgba(253, 64, 86, 0.2);
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .yeterlimi{
    margin-top: 100px;
    width: 100%;
    height: 100px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 #2d36400c;
    border: solid 1px #eaedf2;
    background-color: #00a575;
    display: flex;
    align-items: center;

    .y-text{
      margin-left: 20px;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.75;
      letter-spacing: normal;
      color: #ffffff;
      margin-right: 80px;
    }

    .y-button{
      margin-right: 30px;
      padding-left: 25px;
      padding-right: 25px;
      height: 44px;
      border-radius: 2px;
      border: solid 1px #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #00a575;
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      color: #ffffff;
      &:hover{
        background: #00a575!important;
        color: #ffffff;
        opacity: 0.8;
      }
    }

  }

  .other-category{
    margin-top: 30px;
    .main-title{
      font-size: 46px;
      font-weight: bold;
      color: #2d3640;
    }

    .main-sub-title{
      font-size: 24px;
      font-weight: normal;
      line-height: 1.42;
      color: #5e6b79;
      margin-top: 5px;
    }


    .sub-categories{
      margin-top: 50px;
      .sub-category{
        font-size: 18px;
        font-weight: normal;
        line-height: 1.67;

        margin-bottom: 15px;
        a{
          color: #6d7174;
          &:hover{
            color: #00a575;
            text-decoration: none!important;
          }
        }
      }
    }
  }


  .inner-headers {
    font-size: 34px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2d3640;
  }
  .inner-header-description {
    margin-top: 10px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    color: #5e6b79;
  }

  .big-icon /deep/{
    width: 50px;
    height: 46px;
    path {
      fill: #fd4056;
    }
  }

  .support-icon /deep/{
    width: 24px;
    height: 24px;
    path {
      fill: #00a575;
    }
  }

  .support-icon-container {
    min-width: 60px;
    width: 60px;
    height: 60px;
    border-radius: 50px;
    box-shadow: 0 3px 6px 0 rgba(253, 64, 86, 0.2);
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .new-ticket-bar-container {
    margin-top: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #00a575;
    padding: 20px 20px;
    max-width: 85%;
    align-self: center;
  }
  .new-ticket-bar-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    margin: 0px 20px;
    color: #ffffff;
  }
  .new-ticket-button {
    height: 44px;
    border-radius: 2px;
    border: solid 1px #ffffff;
    background-color: transparent;
    min-width: 190px;
    cursor: pointer;
    display:flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: transparent;
      text-decoration: none;
      span{
        text-decoration: none;
      }
    }
  }
  .new-ticket-button-text {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }


  .left-bar-header {
    margin-top: 42px;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2d3640;
  }
  .category-detail-item {
    margin-left: 37px;
    margin-top: 16px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    cursor: pointer;
  }
  .bionluk-copyright-text {
    margin-top: 20px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8b95a1;
  }

  .selected-category-item {
    font-size: 14px;
    font-weight: 500!important;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #2d3640!important;
    height: 40px;
    display: flex;
    align-items: center;
    width: 200px;
    &:hover{
      color: #2d3640;
      text-decoration: none;
    }
  }
  .category-item {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #8b95a1;
    padding-right: 50px;
    &:hover{
      color: #2d3640;
      text-decoration: none;
    }
  }

  .selected-category-row {
    border-right: 3px solid #fd4056;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    padding-right: 47px;
    color: #8b95a1;
  }

  .sub-link-p{
    width: 170px;
    margin-left: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    a{
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #8b95a1;
      &:hover{
        color: #2d3640;
        text-decoration: none;
      }
    }
  }

  .sub-link-p-active{
    width: 170px;
    margin-left: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    a{
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #2d3640;
      &:hover{
        color: #2d3640;
        text-decoration: none;
      }
    }
  }

  .selectedCat {
    opacity: 1;
    max-height: 1000px;
  }
  .unselectedCat {
    transition: all 1.4s ease-out;
    opacity: 0;
    overflow-y: hidden;
    max-height: 0px;
  }
  .selectedCatDetail {
    font-weight: 500;
    color: #2d3640;
  }
  .unselectedCatDetail {
    font-weight: normal;
    color: #8b95a1;
  }


  .useful-content-title {
    font-size: 26px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2d3640;
    margin-top: 20px;
    cursor: pointer;
    &:hover{
      text-decoration: none;
    }
  }

  .useful-content-detail {
    font-size: 18px;

    line-height: 1.67;

    color: #6d7174;
    max-width: 250px;

    margin-top: 15px;
    &:hover {
      color: #00a575;
      text-decoration: none;
    }
    a{
      font-size: 18px;

      line-height: 1.67;

      color: #6d7174;
      max-width: 250px;

      margin-top: 15px;
      &:hover {
        color: #00a575;
        text-decoration: none;
      }
    }
  }

  .useful-content-show-more {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #fd4056;

    cursor: pointer;
  }

</style>
