<template>
  <div style="display:flex;justify-content:space-between;">
    <div style="display:flex; align-items:center">
      <div
        v-for="(item,index) in contentData.navBarList"
        :key="index"
        style="display:flex;align-items:center;">
        <p
          @click="navBarItemClick(item.path)"
          v-bind:class="index != contentData.navBarList.length-1 ? 'navbar-item':'last-navbar-item'"
        >{{item.title}}</p>


        <img
          onload="SVGInject(this)"
          v-if="index != contentData.navBarList.length-1"
          src="https://gcdn.bionluk.com/site/cicons/y/ic-arrows-right.svg"
          class="arrow-right-iconf"/>
      </div>
    </div>
    <div
      v-if="showButton"
      @click="buttonClick"
      style="
  border-radius: 2px;
  border: solid 1px rgba(45, 54, 64, 0.2);
  background-color: #2d3640;
  padding: 0px 30px;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    height:44px;
  "
    >
      <div
        style="  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  "
      >{{contentData.buttonTitle}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelpNavBar",
  props: ["contentData", "showButton"],

  methods: {
    buttonClick: function() {
      this.$router.push(this.contentData.buttonPath);
    },
    navBarItemClick: function(path) {
      this.$router.push(path);
    }
  }
};
</script>
<style scoped lang="scss">

  .arrow-right-iconf /deep/{

    margin-left: 8px;
    margin-right: 8px;
    width: 12px;
    height: 12px;

    path {
      fill: #8b95a1;
    }
  }



.navbar-item {
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;

  letter-spacing: normal;
  color: #8b95a1;
  padding-bottom: 2px;
  max-width: 200px;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover{
    color: #2d3640;
    text-decoration: underline;
  }
}
.last-navbar-item {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  padding-bottom: 2px;
  letter-spacing: normal;
  color: #2d3640;
  max-width: 200px;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover{

    text-decoration: underline;
  }
}
</style>
